import React, { useContext, useEffect } from "react";
import AppContext from "@src/context";
import Seo from "@components/utility/SEO";
import Image from "@components/utility/Image";
import { withArtDirection, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { KfHeroUtility, KfAnchorLink } from "@klickinc/kf-react-components";
import "./styles.scss";

const ContactUs = ({ data }) => {
	const ctx = useContext(AppContext);

	const image_verticalLine = withArtDirection(getImage(data.verticalLineMedium), [
		{
			media: "(max-width: 640px)",
			image: getImage(data.verticalLineSmall),
		},
	]);
	const image_contact = getImage(data.contact);

	useEffect(() => {
		ctx.setPageClass("page--contact-us relative min-h-screen");
	}, []);

	return (
		<>
			<section className='relative'>
				<div className='bg-1-image'>
					<Image
						imageData={image_verticalLine}
						objectFit='contain'
						alt=''
						loading='eager'
					/>
				</div>
				<div className="container container--inner">
					<div className='max-w-3xl'>
						<KfHeroUtility addedClass="heading heading-hero-blue">
							Contact Us
						</KfHeroUtility>

						<div className='mb-9'>
							<h2 className="orange-heading uppercase my-5 md:my-0 md:mb-5 md:text-left">
								For inquiries pertaining to emflaza
							</h2>
							<p className="mb-3 lg:mb-7">
								For medical information, product complains, or to report an adverse event, please call <KfAnchorLink url='tel:18665624620' target="_blank" linkClass='link underline'>1&#8209;866&#8209;562&#8209;4620</KfAnchorLink> or email at <KfAnchorLink url='mailto:usmedinfo@ptcbio.com' target="_blank" linkClass='link underline'>usmedinfo@ptcbio.com</KfAnchorLink>.
							</p>

							<p>
								You may also report side effects to FDA at <KfAnchorLink url='tel:18003321088' target="_blank" linkClass='link underline'>1&#8209;800&#8209;FDA&#8209;1088</KfAnchorLink> or at <a href="https://www.fda.gov/medwatch" target='_blank' rel="noreferrer" className="link underline">www.fda.gov/medwatch</a>.
							</p>
						</div>

						<div
							className=' mb-9'
						>
							<h2 className="orange-heading uppercase my-5 md:my-0 md:mb-5 md:text-left">
								PTC <span className='italic'>Cares</span><sup>&trade;</sup> support program
							</h2>
							<p className="mb-3 lg:mb-7">
								PTC <span className='italic'>Cares</span> is a support program designed to help you get your child started on treatment, continue treatment uninterrupted, and support you throughout the treatment journey.
							</p>

							<div className='flex flex-col'>
								<div className='flex space-x-1'>
									<span className='font-bold'>Phone: </span> <KfAnchorLink url='tel:18444782227' target="_blank" linkClass='link underline'>1&#8209;844&#8209;478&#8209;2227</KfAnchorLink>
								</div>
								<div className='flex space-x-1'>
									<span className='font-bold'>Site: </span> <KfAnchorLink url='https://ptccares.com/' target="_blank" linkClass='link underline'>ptccares.com</KfAnchorLink>
								</div>
							</div>
						</div>

						<div
							className=' mb-9'
						>
							<h2 className="orange-heading uppercase my-5 md:my-0 md:mb-5 md:text-left">
								PTC peer navigator program
							</h2>
							<p className="mb-3 lg:mb-7">
								This program connects you with other families familiar with EMFLAZA who can provide you with the support and guidance you need to better understand your child's treatment options. To be connected with a PTC Peer Navigator, email <KfAnchorLink url='mailto:peernavigator@ptcbio.com' target="_blank" linkClass='link underline'>peernavigator@ptcbio.com</KfAnchorLink> or call <KfAnchorLink url='tel:18662825873' target="_blank" linkClass='link underline'>1&#8209;866&#8209;282&#8209;5873</KfAnchorLink>.

							</p>
						</div>


						<div
							className='pb-8  mb-9 md:pb-0'
						>
							<h2 className="orange-heading uppercase my-5 md:my-0 md:mb-5 md:text-left">
								Corporate headquarters
							</h2>
							<div className='mb-4'>
								<span className='text-emflaza-blue-200 font-bold'>PTC Therapeutics Inc.</span><br />
								<span>500 Warren Corporate Center Drive</span><br />
								<span>Warren, NJ 07059</span><br />
								<div className='flex space-x-1'>
									<span className='font-bold'>Phone: </span> <KfAnchorLink url='tel:9082227000' target="_blank" linkClass='link underline'>908&#8209;222&#8209;7000</KfAnchorLink>
								</div>
								<div className='flex space-x-1'>
									<span className='font-bold'>Fax: </span> <KfAnchorLink url='tel:9082227231' target="_blank" linkClass='link underline'>908&#8209;222&#8209;7231</KfAnchorLink>
								</div>
							</div>

							<div>
								<div className='flex space-x-1'>
									<span className='font-bold'>For general inquiries:</span> <KfAnchorLink url='mailto:info@ptcbio.com' target="_blank" linkClass='link underline'>info@ptcbio.com</KfAnchorLink>
								</div>
								<div className='flex space-x-1'>
									<span className='font-bold'>For&nbsp;patients&nbsp;and&nbsp;families:</span> <KfAnchorLink url='mailto:patientInfo@ptcbio.com' target="_blank" linkClass='link underline'>patientInfo@ptcbio.com</KfAnchorLink>
								</div>
								<div className='flex space-x-1'>
									<span className='font-bold'>For physicians:</span> <KfAnchorLink url='mailto:medInfo@ptcbio.com' target="_blank" linkClass='link underline'>medInfo@ptcbio.com</KfAnchorLink>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="bg-bottom hidden lg:block">
					<Image
						imageData={image_contact}
						alt=''
						loading='eager'
					/>
				</div>
			</section>
		</>
	);
};

export default ContactUs;


export const pageQuery = graphql`
  query {
    verticalLineSmall: file(relativePath: {eq: "pages/getting-started/vertical-line-1-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	verticalLineMedium: file(relativePath: {eq: "pages/getting-started/vertical-line-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	contact: file(relativePath: {eq: "pages/contact-us/bg-bottom-contact-us.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
}
`
export function Head() {
	return (
		<Seo
			title="Contact Us - EMFLAZA® (deflazacort)"
			description='See contact information for inquiries pertaining to EMFLAZA® (deflazacort), the PTC Cares™ Support Program, and the PTC Peer Navigator Program. See full Prescribing Information & Important Safety Information.'
			addSocialMeta={false}
		/>
	);
};
